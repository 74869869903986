.json-viewer-container-wrapper {
  position: relative;
  .json-viewer-container {
    padding: 6px;
    border: 1px solid var(--detail-border);
    border-radius: 5px;
    overflow: auto;
  }

  .copy-to-clipboard {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
}
