.search-input .form-label {
  margin-bottom: 0;
}

.search-input input {
  text-overflow: ellipsis;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--detail-border) !important;
  border-left: none;
  border-radius: 0;
  transition: border-bottom-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-input input.form-control.is-invalid {
  border-bottom-color: var(--warning-error) !important;
}

.search-input input:focus {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid var(--detail-border) !important;
  box-shadow: none !important;
}

.search-input button {
  padding: 0;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--detail-border) !important;
  border-left: none;
  border-radius: 0;
  display: flex;
  align-items: center;
}

.search-input button,
.search-input button:focus,
.search-input button:active {
  background-color: transparent !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom-color: var(--detail-border) !important;
  box-shadow: none !important;
}

.search-input .invalid-feedback {
  display: block;
  min-height: 21px;
}
