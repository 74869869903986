.execution-details-info {
  .side-informations {
    display: flex;
    flex-direction: column;
  }
}

.execution-details-info.status {
  height: 43px;
  font-weight: 700;
}

.execution-details-info.status svg {
  min-width: fit-content;
  margin-right: 8px;
}

.execution-details-info .input > .card-text > .icon-btn {
  margin-left: 8px;
}

.json-viewer {
  height: 300px;
}

.testing-tool-link {
  text-decoration-color: var(--text-black);
}
