.container.metrics-tile {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .col {
    text-align: center;
  }
}
