.multi-select-dropdown .dropdown > .dropdown-toggle.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white !important;
  border-color: #c2cace !important;
  color: #c2cace !important;
}

.multi-select-dropdown.filter .dropdown > .dropdown-toggle.btn {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--detail-border) !important;
  border-left: none;
  border-radius: 0;
  box-shadow: none !important;
}

.multi-select-dropdown .dropdown.selected > .dropdown-toggle.btn {
  color: black !important;
}

.multi-select-dropdown .dropdown-toggle {
  min-height: 38px;
}

.multi-select-dropdown .dropdown-toggle > .badges-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.375rem;
  row-gap: 0.375rem;
}

.multi-select-dropdown .dropdown-toggle > .badges-container .badge {
  margin-right: 0.375rem;
}

.multi-select-dropdown .dropdown,
.multi-select-dropdown .dropdown-toggle,
.multi-select-dropdown .dropdown-menu {
  width: 100%;
}

.multi-select-dropdown .dropdown.disabled {
  cursor: not-allowed;
}

.multi-select-dropdown .dropdown-menu.show {
  overflow-y: scroll;
  max-height: 175px;
}

.multi-select-dropdown .required.form-label:after {
  content: '*';
  color: red;
}

.multi-select-dropdown .dropdown-item.active {
  background-color: var(--primary);
}
