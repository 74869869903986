.container.compare-emails {
  padding: 0;
}

.container.compare-emails .card {
  margin: 32px 24px;
}

.container.compare-emails .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}

.container.compare-emails .row {
  margin-bottom: 24px;
}

.container.compare-emails .comparison-results .email-preview .row.email-execution-id {
  margin: 0 0 8px 0;
}

.container.compare-emails .comparison-results .row.email-execution-id .col {
  height: 43px;
  padding: 0;
  background-color: var(--tertiary);
  color: var(--text-gray);
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
}

.container.compare-emails .comparison-results .email-preview .row.subject-recipient .col {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
}

.container.compare-emails .comparison-diff .row.diff {
  max-width: calc(100vw - 104px - 2 * 24px - 32px - 38px - 19px);
  max-height: 500px;
  overflow: auto;
}

.container.compare-emails .row.loader > .col {
  height: 17rem;
}

.container.compare-emails .row.loader > .col > .spinner-border.text-success {
  margin-top: 64px;
  width: 4rem;
  height: 4rem;
}

.compare-emails .loader h1,
.compare-emails .loader h4,
.compare-emails .comparison-diff .no-diffs-found h1,
.compare-emails .comparison-diff .no-diffs-found h4 {
  font-weight: 700;
}

.compare-emails .comparison-diff .no-diffs-found img {
  margin-bottom: 32px;
}

.compare-emails .loader h1,
.compare-emails .comparison-diff .no-diffs-found h1 {
  font-size: 48px;
  color: var(--primary);
  margin-bottom: 12px;
}

.compare-emails .loader h4,
.compare-emails .comparison-diff .no-diffs-found h4 {
  font-size: 24px;
  color: var(--text-gray);
}

.compare-emails .loader > .col,
.compare-emails .comparison-diff .row.no-results-found > .col {
  height: 17rem;
}
