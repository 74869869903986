.permission-features-container {
  display: grid;
  grid-template-columns: 1fr 3fr 7fr;
  justify-content: flex-start;

  padding: 8px;

  &[data-disabled='true'] {
    opacity: 0.5;
  }

  &:not(:first-child) {
    border-top: 1px solid var(--detail-border);
  }
}
