.container.testing-tool {
  padding: 0;
}

.container.testing-tool .card {
  margin: 32px 24px;
}

.container.testing-tool .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}

.container.testing-tool .row {
  margin-bottom: 24px;
}

@keyframes animationName {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes animationName {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes animationName {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes animationName {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.elementToFadeInAndOut {
  -webkit-animation: animationName 5s infinite;
  -moz-animation: animationName 5s infinite;
  -o-animation: animationName 5s infinite;
  animation: animationName 5s infinite;
}
