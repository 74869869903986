.container.dashboard {
  padding: 0;
}

.container.dashboard .card {
  margin: 32px 24px;
}

.container.dashboard .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}

.container.dashboard .row {
  margin-bottom: 24px;
}

.container.dashboard .row.indicators {
  gap: 32px;
}

.container.dashboard .separator {
  border-bottom: 1px solid var(--detail-border);
  padding-bottom: 16px;
}

.container.dashboard .row.indicators .col-md-4 {
  width: calc(33.33333333% - 32px);
}
