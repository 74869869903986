.permissions-modal-container {
  height: 540px;
  width: 900px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  --header-height: 65px;
  --footer-height: 60px;

  .permissions-modal-container-header {
    height: var(--header-height);
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 40px;
  }

  .permissions-modal-container-content {
    height: calc(100% - (var(--header-height) + var(--footer-height)));
    padding-top: 16px;

    > header {
      display: grid;
      grid-template-columns: 3fr 7fr;
      height: 48px;
      padding: 8px 12px;
      padding-left: 80px;

      font-size: 16px;
      color: var(--primary);
      font-weight: bold;

      border-bottom: 4px solid var(--primary);
    }

    .permissions-modal-container-content-list {
      height: calc(100% - 48px);
      overflow-y: auto;
    }
  }

  .permissions-modal-container-footer {
    height: var(--footer-height);
    padding: 6px 0;

    p {
      margin-bottom: 0 !important;

      .label {
        color: var(--text-gray);
        margin-right: 4px;
      }

      .permissions {
        color: var(--text-black);
      }
    }
  }
}
