td.table-cell-overlay.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
  display: inline-flex;
}

td.table-cell-overlay.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium span {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 16px;
}

td.table-cell-overlay.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium .icon-btn {
  padding: 0;
}
