.closable-badge.badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-black);
}

.closable-badge.badge.bg-primary.gray {
  background-color: #f0f1ff !important;
}

.closable-badge.badge.bg-primary.red {
  background-color: var(--bg-red) !important;
}

.closable-badge.badge.bg-primary.orange {
  background-color: var(--bg-yellow) !important;
}

.closable-badge.badge.bg-primary.green {
  background-color: var(--bg-green) !important;
}

.closable-badge.badge .btn-close {
  width: 8px;
  height: 8px;
  padding: 0 0 0 1em;
}
