.row.execution-history-details.reprocess > .col > .btn {
  font-size: 16px;
  font-weight: 400;
  width: 280px;
  height: 43px;
}

.row.execution-history-details .spinner-border.text-success {
  width: 4rem;
  height: 4rem;
}

.completed-steps-item {
  display: flex;
  align-items: center;
  gap: 5px;

  .completed-animation {
    transition: color 1000ms linear;
  }
}
