.single-select-dropdown .dropdown > .dropdown-toggle.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-white) !important;
  border-color: var(--detail-border) !important;
  color: var(--text-gray) !important;
}

.single-select-dropdown.filter .dropdown > .dropdown-toggle.btn {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--detail-border) !important;
  border-left: none;
  border-radius: 0;
  box-shadow: none !important;
}

.single-select-dropdown .dropdown.selected > .dropdown-toggle.btn {
  color: var(--text-black) !important;
}

.single-select-dropdown .dropdown-toggle {
  min-height: 38px;
}

.single-select-dropdown .dropdown-toggle::after {
  color: var(--detail-gray);
}

.single-select-dropdown .dropdown-toggle,
.single-select-dropdown .dropdown-menu {
  width: 100%;
}

.single-select-dropdown .dropdown.disabled {
  cursor: not-allowed;
}

.single-select-dropdown .dropdown-menu.show {
  overflow-y: scroll;
  max-height: 175px;
}

.single-select-dropdown .dropdown-item.active {
  background-color: var(--primary);
}
