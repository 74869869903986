.execution-email-preview-modal .modal-content {
  max-height: 80vh;
}

.execution-email-preview-modal .modal-content > .modal-header {
  padding: 24px 16px 0px 24px;
  align-items: normal;
}

.execution-email-preview-modal .modal-content > .modal-header > .col > .icon-btn.link {
  width: calc(100% + 2 * 24px + 16px);
  margin: 24px 0 0 -24px;
  border-radius: 0;
  background-color: var(--primary) !important;
  color: var(--bg-white) !important;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  justify-content: center;
}

.execution-email-preview-modal .modal-content > .modal-body {
  overflow: auto;
  padding: 0;
}

.execution-email-preview-modal .modal-content > .modal-body .row.loader > .col {
  height: 20rem;
}

.execution-email-preview-modal .modal-content > .modal-body .row.loader > .col > .spinner-border.text-success {
  width: 4rem;
  height: 4rem;
}
