.notification.toast-container {
  position: fixed !important;
  z-index: 3;
}

.notification.toast-container .bg-success .toast-header,
.notification.toast-container .bg-success {
  border-color: var(--primary);
  color: var(--primary);
  background-color: var(--tertiary) !important;
}

.notification.toast-container .bg-warning .toast-header,
.notification.toast-container .bg-warning {
  background-color: var(--tertiary) !important;
  border-color: var(--warning-orange);
  color: var(--bg-warning);
}

.notification.toast-container .bg-danger .toast-header,
.notification.toast-container .bg-danger {
  background-color: var(--tertiary) !important;
  border-color: var(--warning-error);
  color: var(--warning-error);
}
