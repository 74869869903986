.card.execution-history-details {
  margin: 32px 24px;
}

.card.execution-history-details .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}
