aside {
  position: fixed;
  top: 56px;
  height: calc(100vh - 56px);
  z-index: 1002;
}

#main {
  width: 100%;
  margin-left: 104px;
}

aside.open ~ #main {
  height: calc(100vh - 56px);
  overflow-y: hidden;
}

aside.closed ~ #main::after {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: '';
  background-color: transparent;
  transition: background-color 0.2s linear;
}

aside.open ~ #main::after {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1001;
  content: '';
  background-color: rgba(10, 10, 10, 0.8);
  transition: background-color 0.2s linear;
}

@media (min-width: 1400px) {
  #main > .container,
  #main > .container-lg,
  #main > .container-md,
  #main > .container-sm,
  #main > .container-xl,
  #main > .container-xxl {
    max-width: 1600px;
  }
}

.aside-main {
  display: flex;
}
