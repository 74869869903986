.datepicker.filter input {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--detail-border) !important;
  border-left: none;
  border-radius: 0;
}

.datepicker.filter input:focus {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--detail-border) !important;
  border-left: none;
  box-shadow: none !important;
}

.datepicker input.form-control.is-invalid {
  border-bottom-color: var(--warning-error) !important;
}

.datepicker .invalid-feedback {
  display: block;
  min-height: 5px;
}

.datepicker .invalid-feedback div {
  position: absolute;
}
