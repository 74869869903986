.reports-form {
  width: 100%;

  .row {
    row-gap: 16px;
  }

  hr {
    margin: 32px 0;
    background: #e0e0e0;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-left: auto;
  }
}
