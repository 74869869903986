.multi-select-dropdown {
  label {
    color: var(--text-gray);
    margin-bottom: 0;
  }

  &.secondary {
    label {
      font-size: 16px;
    }
  }

  &.primary {
    label {
      font-size: 12px;
    }
  }

  .select-button {
    position: relative;
    cursor: default;

    font-size: 16px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.8rem;

    overflow: hidden;
    text-overflow: ellipsis;

    border-bottom: 1px solid var(--detail-border);

    &::after {
      position: absolute;
      right: 0;
      top: 50%;
    }
  }
}

.multi-select-dropdown .dropdown-toggle > .badges-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.375rem;
  row-gap: 0.375rem;
}

.multi-select-dropdown .dropdown-toggle > .badges-container .badge {
  margin-right: 0.375rem;
}

.multi-select-dropdown .dropdown,
.multi-select-dropdown .dropdown-toggle,
.multi-select-dropdown .dropdown-menu {
  width: 100%;
}

.multi-select-dropdown .dropdown.disabled {
  cursor: not-allowed;
}

.multi-select-dropdown .dropdown-menu.show {
  overflow-y: auto;
  max-height: 175px;
}

.multi-select-dropdown .required.form-label:after {
  content: '*';
  color: var(--warning-error);
}
