.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
  font-family: 'Lato', sans-serif;
}

.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root.Mui-focused > fieldset {
  border-width: 1px;
}

.async-autocomplete.filter > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
  padding-top: 0;
  padding-bottom: 0;
}

.async-autocomplete.filter > .MuiAutocomplete-root > .MuiFormControl-root .MuiSvgIcon-root {
  fill: var(--detail-gray) !important;
}

.async-autocomplete.filter > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root > fieldset {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--detail-border) !important;
  border-left: none;
  border-radius: 0;
  box-shadow: none !important;
}

.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root > fieldset > legend {
  display: none;
}

.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root > input::placeholder {
  color: black !important;
}

.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root > input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.MuiAutocomplete-listbox {
  overflow-x: hidden !important;
}
