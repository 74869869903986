:root {
  --primary: #2b6747;
  --secondary: #c9dfd3;
  --tertiary: #f0f8f4;

  --text-black: #424242;
  --text-gray: #858e93;

  --detail-gray: #a1acb2;
  --detail-border: #cdcdcd;

  --warning-error: #ff4949;
  --warning-success: #4aa04a;
  --warning-orange: #fa9f47;
  --warning-blue: #4994ee;
  --warning-disable: #dddddd;

  --bg-white: #ffffff;
  --bg-gray: #f5f5f5;
  --bg-yellow: #fff3d6;
  --bg-red: #fff0f0;
  --bg-green: #cdfcbd;
  --bg-blue: #e4effd;

  --box-shadow-small: 0px 2px 4px #b3b3b3;
}

body {
  margin: 0;
  background-color: var(--tertiary);
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea:focus,
input:focus,
button:focus {
  box-shadow: unset !important;
}

input {
  color: var(--text-black) !important;
}

input::placeholder {
  color: var(--text-gray) !important;
}

.form-control:focus {
  border: 1px solid var(--detail-border) !important;
}

button:focus {
  border-color: var(--primary) !important;
}

a:hover {
  color: var(--primary);
}

.container {
  background-color: transparent;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.form-control::placeholder {
  color: var(--text-gray) !important;
}

.badge.bg-primary,
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.dropdown-item:active {
  background-color: var(--primary) !important;
}

.badge {
  border-radius: 72px;
}

.btn-primary {
  border-color: var(--primary);
}

.btn-primary:disabled {
  pointer-events: unset;
  cursor: not-allowed;
}

.required.form-label:after {
  content: '*';
  color: var(--warning-error);
}

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd > span {
  font-family: 'Lato', sans-serif;
}

.form-label {
  color: var(--text-gray);
}

.filter .form-label {
  margin-bottom: 0 !important;
}

.spinner-border:not(.vanilla) {
  border-color: var(--primary);
  border-right-color: transparent;
}

.fancy-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--detail-border);
    border-radius: 16px;
  }
}
