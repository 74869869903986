#paginated-table {
  max-width: 100% !important;
}

.paginated-table tfoot > tr > td > div > p {
  margin: 0;
}

.paginated-table thead > tr > th {
  color: var(--primary);
  background-color: var(--bg-white);
  border-color: var(--primary);
  font-family: 'Lato', sans-serif !important;
}

.paginated-table thead > tr.rows-selected > th {
  color: var(--bg-white) !important;
  background-color: var(--primary) !important;
  border-color: none !important;
}

.paginated-table thead > tr.rows-selected > th button.icon-btn.link {
  color: var(--bg-white) !important;
  border-color: var(--bg-white) !important;
}

.paginated-table p {
  margin-bottom: 0;
}

.no-results-found h1,
.no-results-found h4 {
  font-weight: 700;
}

.no-results-found img {
  margin-bottom: 32px;
}

.no-results-found h1 {
  font-size: 48px;
  color: var(--primary);
  margin-bottom: 12px;
}

.no-results-found h4 {
  font-size: 24px;
  color: var(--text-gray);
}

.row.no-results-found > .col,
.row.loader > .col {
  height: 17rem;
}

.row.loader > .col > .spinner-border.text-success {
  width: 4rem;
  height: 4rem;
}
