.container.exception-history {
  padding: 0;
}

.container.exception-history .card {
  margin: 32px 24px;
}

.container.exception-history .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}

.container.exception-history .row {
  margin-bottom: 24px;
}
