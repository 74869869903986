.container.reports {
  padding: 0;

  .card {
    margin: 32px 24px;
  }

  .card-body {
    margin-top: 28px;
    margin-left: 32px;
    margin-right: 38px;
    margin-bottom: 45px;
    padding: 0px !important;
  }

  .download-report {
    flex: 0 content;

    button {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--warning-blue);
      background: none;
      border: none;
    }
  }
}
