.testing-tool-form > .row {
  row-gap: 16px;
}

.testing-tool-form .form-text.text-muted {
  font-size: 12px;
}

.testing-tool-form > .row.message {
  border-top: 1px solid var(--secondary);
  padding-top: 16px;
}

.testing-tool-form > .row.message textarea {
  height: 550px;
  max-height: 550px;
}

.testing-tool-form > .row.message .invalid-feedback {
  display: block;
  min-height: 21px;
}
